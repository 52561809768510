<template>
  <v-app-bar
    id="d-app-bar"
    absolute
    app
    color="white"
    elevation="1"
    height="70"
  >
    <!-- <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn> -->

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />

    <div class="mx-3" />

    <v-btn
      class="ml-2"
      min-width="0"
      icon
      small
      dark
      color="black"
      to="/"
    >
      <v-icon>mdi-home</v-icon>
    </v-btn>

    <v-btn
      v-if="this.$route.name !== 'Dashboard'"
      class="ml-2"
      min-width="0"
      icon
      small
      dark
      color="black"
      @click="gotodashboard()"
    >
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <div>
      <v-tabs
        class="hidden-sm-and-down"
        optional
      >
        <v-tab
          v-for="item in menu"
          :key="item.name"
          :to="{ path: item.to }"
          :exact="item.name === 'Home'"
          :ripple="false"
          active-class="text--primary"
          class="font-weight-bold"
          min-width="96"
          text
        >
          {{ $t(item.name) }}
        </v-tab>
      </v-tabs>
    </div>

    <v-menu
      v-if="$store.state.auth.authStatus === 'success'"
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-avatar
          size="30"
          class="ml-2"
          v-bind="attrs"
          v-on="on"
        >
          <v-img :src="computedAvatar" />
        </v-avatar>
      </template>

      <v-list
        :tile="false"
        flat
        nav
      >
        <template v-for="(p, i) in profile">
          <v-divider
            v-if="p.divider"
            :key="`divider-${i}`"
            class="mb-1 mt-1"
          />

          <app-bar-item
            v-else
            :key="`item-${i}`"
            :to="p.path"
          >
            <v-icon left>
              {{ p.icon }}
            </v-icon>
            <v-list-item-title v-text="$t(p.title)" />
          </app-bar-item>
        </template>
      </v-list>
    </v-menu>

    <base-locale-switcher />
  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      // navigation: null,
      // drawer: null,
      menu: [
        { name: 'sections.active-forms', secured: false, to: '/account/active' },
        { name: 'sections.projects', secured: false, to: '/account/projects' },
      ],
    }),

    computed: {
      ...mapState(['drawer']),

      computedAvatar () {
        const image = ''
        return image ? this.$store.state.auth.user.avatar : require('@/assets/accounticon.png')
      },

      profile () {
        return [
          { title: this.$t('sections.active-forms'), path: '/account/active', icon: 'mdi-earth' },
          { title: this.$t('common.projects'), path: '/account/projects', icon: 'mdi-tag-multiple' },
          { title: this.$t('common.settings'), path: '/account/settings', icon: 'mdi-account-cog' },
          { divider: true },
          { title: this.$t('oth.logout'), path: '/logout', icon: 'mdi-power' },
        ]
      },

    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),

      logout: function () {
        this.$store.dispatch('logout').then(() => {
          this.$router.push('/')
        })
      },

      projectSelected: function (nid) {
        // console.log('projectSelected')
        const myProject = this.$store.state.bend.onlineProjects.filter(function (
          onlineProjects,
        ) {
          return onlineProjects.nid === nid
        })
        this.$store.dispatch('bend/projectSelected', myProject)
      },

      allProjects () {
        return this.$store.state.bend.onlineProjects
      },

      gotodashboard () {
        this.$router.push({ name: 'Dashboard' })
      },

    },
  }
</script>
